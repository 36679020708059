import React, { useState, useEffect } from "react";
import { Range, getTrackBackground } from "react-range";
import translate from "../../../../i18n/translate";
import { FormattedMessage } from "react-intl";
const STEP = 1;
const MIN = 0;
const MAX = 21;
const ZoomForm = (props) => {
  const [check, setCheck] = useState(props.geoservice === 0 ? false : true);
  const [values, setValues] = useState([props.zoom ? props.zoom : 10]);
  const { setZoom, geoservice } = props;
  useEffect(() => {
    if (geoservice !== 0) {
      setCheck(true);
      setValues([0]);
    }
  }, [geoservice]);

  useEffect(() => {
    setZoom(values[0]);
  }, [values, setZoom]);

  const handleCheckbox = (e) => {
    if (check) {
      setCheck(false);
    } else {
      setCheck(true);
      setValues([0]);
    }
  };
  return (
    <React.Fragment>
      <div className="form-group">
        <label>
          <FormattedMessage id="vidromaps-initial-zoom" />
        </label>
        {props.geoservice !== 0 && (
          <div className="form-group">
            <input
              key="zoomcheck"
              type="checkbox"
              name="zoom"
              id="zoomtoextend"
              checked={check}
              onChange={(e) => handleCheckbox(e)}
            />
            <label className="ml-1">
              {translate("vidromaps-zoom-to-extent")}
            </label>
          </div>
        )}
      </div>
      {!check && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <Range
            values={values}
            step={STEP}
            min={MIN}
            max={MAX}
            onChange={(values) => setValues(values)}
            renderTrack={({ props, children }) => (
              <div
                onMouseDown={props.onMouseDown}
                onTouchStart={props.onTouchStart}
                style={{
                  ...props.style,
                  height: "36px",
                  display: "flex",
                  width: "100%",
                }}
              >
                <div
                  ref={props.ref}
                  style={{
                    height: "5px",
                    width: "100%",
                    borderRadius: "4px",
                    background: getTrackBackground({
                      values,
                      colors: ["#548BF4", "#ccc"],
                      min: MIN,
                      max: MAX,
                    }),
                    alignSelf: "center",
                  }}
                >
                  {children}
                </div>
              </div>
            )}
            renderThumb={({ props, isDragged }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "42px",
                  width: "42px",
                  borderRadius: "4px",
                  backgroundColor: "#FFF",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "0px 2px 6px #AAA",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "-28px",
                    color: "#fff",
                    fontWeight: "bold",
                    fontSize: "14px",
                    fontFamily: "Arial,Helvetica Neue,Helvetica,sans-serif",
                    padding: "4px",
                    borderRadius: "4px",
                    backgroundColor: "#548BF4",
                  }}
                >
                  {values[0].toFixed(1)}
                </div>
                <div
                  style={{
                    height: "16px",
                    width: "5px",
                    backgroundColor: isDragged ? "#548BF4" : "#CCC",
                  }}
                />
              </div>
            )}
          />

          <small id="passwordHelpBlock" className="form-text text-muted">
            <FormattedMessage id="vidromaps-zoom-help" />
          </small>
        </div>
      )}
    </React.Fragment>
  );
};
export default ZoomForm;
