import React from "react";
//import classNames from "classnames";
import { FormattedMessage } from "react-intl";

const GeoserviceForm = (props) => {
  const geoservices = [
    { id: "QGIS", name: "QGIS" },
    { id: "Other", name: "Other" },
    { id: 0, name: "No map service" },
  ];

  const handleOnChange = (e) => {
    const { value } = e.target;
    props.setGeoservice(value);
  };
  return (
    <div className="form-group">
      <label>
        <FormattedMessage id="vidromaps-geo-service" />
      </label>
      <select
        name="geoservice"
        className="form-control"
        onChange={handleOnChange}
        value={props.geoservice}
      >
        {geoservices.map((service, index) => {
          return (
            <option key={index} value={service.id}>
              {service.name}
            </option>
          );
        })}
      </select>
    </div>
  );
};
export default GeoserviceForm;
