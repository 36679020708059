import React, { Component } from "react";
import { withRouter, NavLink, Link } from "react-router-dom";
import classNames from "classnames";

//translate
import translate from "../../i18n/translate";

//components
import IconHome from "../icons/home";
import IconSettings from "../icons/settings";
import LocalPay from "../icons/localpay";
import IconMap from "../icons/map";
import UserDesktop from "../icons/userDesktop";
import IconHerramienta from "../icons/herramienta";

//style
import "./index.scss";
import IconCloseDesple from "../icons/closeDesple";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users:
        this.props.match.path === "/administracion/:section" ? true : false,
      admin: false,
      tools: this.props.match.path === "/tools/:section" ? true : false,
    };
  }

  goToUrl = (url) => {
    if (this.props.showSidebar !== null) {
      this.props.showSidebar();
    }
    if (this.props.match.path === url) {
      window.location.reload();
    } else {
      this.props.history.push(url);
    }
  };

  render() {
    const { match } = this.props;
    return (
      <div className="sidebar-content">
        <ul className="menu-ul">
          <li>
            <NavLink to="/maps" role="button">
              <IconHome />
              {translate("sidebar-home")}
            </NavLink>
          </li>
          <li>
            <NavLink to="/maps" role="button">
              <IconMap color="#A2A9B0" />
              {translate("sidebar-maps")}
            </NavLink>
          </li>
          <li className={classNames({ opendesplegable: this.state.users })}>
            <button
              onClick={(e) =>
                this.setState({
                  users: !this.state.users,
                  admin: false,
                })
              }
              className={classNames("admin", { active: this.state.users })}
            >
              <UserDesktop color="#A2A9B0" />
              <span>{translate("global-users")}</span>
              <IconCloseDesple color="#A2A9B0" />
            </button>
            <ul
              className={classNames("submenu-ul", { open: this.state.users })}
            >
              <li>
                <NavLink
                  to="/administracion/datos"
                  role="button"
                  onClick={this.props.showSidebar}
                >
                  {translate("sidebar-adm-data")}
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/administracion/members"
                  role="button"
                  onClick={this.props.showSidebar}
                >
                  {translate("sidebar-adm-members")}
                </NavLink>
              </li>
            </ul>
          </li>
          <li>
            <NavLink to="/billing" role="button">
              <LocalPay color="#A2A9B0" />
              {translate("herramientas-banner-table-billing")}
            </NavLink>
          </li>

          <li className={classNames({ opendesplegable: this.state.tools })}>
            <button
              onClick={(e) =>
                this.setState({
                  tools: !this.state.tools,
                  admin: false,
                })
              }
              className={classNames("admin", { active: this.state.tools })}
            >
              <IconHerramienta color="#A2A9B0" />
              <span>{translate("sidebar-setting")}</span>
              <IconCloseDesple color="#A2A9B0" />
            </button>
            <ul
              className={classNames("submenu-ul", { open: this.state.tools })}
            >
              <li>
                <NavLink
                  to="/tools/logs"
                  role="button"
                  onClick={this.props.showSidebar}
                >
                  {translate("global-logs")}
                </NavLink>
              </li>
            </ul>
          </li>
          <li>
            <NavLink to="/documentation" role="button">
              <IconSettings color="#A2A9B0" />
              {translate("global-documentation")}
            </NavLink>
          </li>
        </ul>
      </div>
    );
  }
}

export default withRouter(Header);
