import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";

//translate
import translate from "../../../i18n/translate";
import { FormattedMessage } from "react-intl";
//actions
import { addMap, updateMap } from "../../../actions/vidromaps";

// components
import ZoomForm from "./components/zoom";
import BackgroundsForm from "./components/backgrounds";
import GeoserviceForm from "./components/geoservice";
import OtherGeoserviceForm from "./components/othegeoservice";
import ExtentForm from "./components/extent";
import SridForm from "./components/srid";
import LogoForm from "./components/logo";
import NameForm from "./components/name";
import DescriptionForm from "./components/description";
import LayersForm from "./components/layers";
import MapFilesForm from "./components/mapfiles";
import Card from "../../../components/card";
import Button from "../../../components/button";
import Map from "../../../components/icons/map";
//style
//import "../index.scss";

const Register = (props) => {
  const childExtentRef = useRef();
  const childSridRef = useRef();
  const childFilesRef = useRef();
  const childNameRef = useRef();
  const id = props.vidroMap ? props.vidroMap.id : null;
  const map_token = props.vidroMap ? props.vidroMap.map_token : null;
  const [name, setName] = useState(props.vidroMap ? props.vidroMap.name : "");
  const [description, setDescription] = useState(
    props.vidroMap ? props.vidroMap.description : ""
  );
  const [srid, setSrid] = useState(props.vidroMap ? props.vidroMap.srid : "");
  const [zoom, setZoom] = useState(props.vidroMap ? props.vidroMap.zoom : "");
  const [logo, setLogo] = useState(props.vidroMap ? props.vidroMap.logo : "");
  const [files] = useState(props.vidroMap ? props.vidroMap.files : null);
  const [extent, setExtent] = useState(
    props.vidroMap ? props.vidroMap.extent : ""
  );
  const [layers, setLayers] = useState(
    props.vidroMap ? props.vidroMap.layers : ""
  );
  const [external_geo, setExternal_geo] = useState(
    props.vidroMap ? props.vidroMap.external_geo : ""
  );
  const [options, setOptions] = useState(
    props.vidroMap ? props.vidroMap.options : ""
  );
  const [background_id, setBackground_id] = useState(
    props.vidroMap ? props.vidroMap.background_id : "CartoDBLight"
  );
  const [published, setPublished] = useState(
    props.vidroMap ? props.vidroMap.external_geo : 0
  );
  const [geoservice, setGeoservice] = useState(
    props.vidroMap ? props.vidroMap.geoservice : 0
  );

  const [errorCode, setErrorCode] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const [geoproject, setGeoproject] = useState(null);
  const [geoprojectdata, setGeoprojectdata] = useState(null);

  //hide errors when geoservice changes
  useEffect(() => {
    childExtentRef.current.displayError(false);
    childSridRef.current.displayError(false);
  }, [geoservice]);

  const getError = (code, error) => {
    switch (error) {
      case "Invalid MimeType or extension zip":
        return translate("vidromaps-error-zip");
      case "Invalid MimeType or extension qgis":
        return translate("vidromaps-error-qgis");
      default:
        return error;
    }
  };
  const upsert = async () => {
    const map = {
      id,
      name,
      description,
      srid,
      zoom,
      extent,
      logo,
      layers,
      external_geo,
      options,
      background_id,
      published,
      geoservice,
    };

    let error = false;
    setErrorCode(null);
    setErrorMessage(null);
    childNameRef.current.displayError(false);
    childFilesRef.current.displayError("geoproject", false);
    if (name === "") {
      error = true;
      childNameRef.current.displayError(true);
    }
    if (!id) {
      if (geoservice === "QGIS" && geoproject === null) {
        setErrorCode(402);
        setErrorMessage(translate("vidromaps-qgis-file-mandatory"));
        childFilesRef.current.displayError("geoproject", true);
        error = true;
      }
    }
    if (geoservice === 0) {
      if (extent === "") {
        error = true;
        childExtentRef.current.displayError(true);
      }
      if (srid === "") {
        error = true;
        childSridRef.current.displayError(true);
      }
    }

    if (id === null && !error) {
      addMap(map, geoproject, geoprojectdata, props.token)
        .then((res) => {
          props.close(true, true);
        })
        .catch((e) => {
          setErrorCode(402);
          setErrorMessage(getError(402, e.error));
          if (e.error === "Invalid MimeType or extension qgis")
            childFilesRef.current.displayError("geoproject", true);
          if (e.error === "Invalid MimeType or extension zip")
            childFilesRef.current.displayError("data", true);

          console.error("addMap error", e);
        });
    } else if (!error) {
      const [res, code] = await updateMap(props.token, map);

      if (code === 200) {
        props.close(true, true);
      } else if (code === 404) {
        props.close(true, true);
      } else {
        setErrorCode(code);
        setErrorMessage(res.error);
      }
    }
  };
  const closeModal = () => {
    setGeoproject(null);
    setGeoprojectdata(null);
    // setFilesUpdated(null);
  };

  return (
    <React.Fragment>
      <div className="register-banner-modal">
        <Card icon={<Map />} title="Vidromaps" modal closeModal={props.close}>
          <div className="banner-body">
            <ErrorMessage errorCode={errorCode} errorMessage={errorMessage} />
            <form className="banner-modal">
              <NameForm name={name} setName={setName} ref={childNameRef} />
              <DescriptionForm
                description={description}
                setDescription={setDescription}
              />
              <GeoserviceForm
                geoservice={geoservice}
                setGeoservice={setGeoservice}
              />
              <BackgroundsForm
                id={background_id}
                token={props.token}
                setBackground_id={setBackground_id}
              />
              <MapFilesForm
                geoservice={geoservice}
                id={id}
                geoproject={geoproject}
                geoprojectdata={geoprojectdata}
                files={files}
                setGeoproject={setGeoproject}
                setGeoprojectdata={setGeoprojectdata}
                closeModal={closeModal}
                ref={childFilesRef}
                dispatch={props.dispatch}
                token={props.token}
                close={props.close}
              />

              <OtherGeoserviceForm
                options={options}
                external_geo={external_geo}
                setExternal_geo={setExternal_geo}
                setOptions={setOptions}
              />
              <LayersForm
                layers={layers}
                setLayers={setLayers}
                id={id}
                name={name}
                token={props.token}
              />
              <LogoForm logo={logo} setLogo={setLogo} />
              <ZoomForm zoom={zoom} geoservice={geoservice} setZoom={setZoom} />
              <SridForm
                geoservice={geoservice}
                srid={srid}
                setSrid={setSrid}
                ref={childSridRef}
              />
              <ExtentForm
                geoservice={geoservice}
                extent={extent}
                setExtent={setExtent}
                ref={childExtentRef}
              />
            </form>
          </div>
          <div className="button-content">
            <Button rounded onClick={(e) => upsert()}>
              <FormattedMessage id="herramientas-banner-register-save" />
            </Button>
            <Button rounded onClick={(e) => props.close(true)} red>
              <FormattedMessage id="herramientas-banner-register-cancel" />
            </Button>
          </div>
        </Card>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
  };
};

const ErrorMessage = ({ errorCode, errorMessage }) => {
  if (!errorCode) return null;
  return (
    <div className="alert alert-danger" role="alert">
      {errorCode === 399 ||
      errorCode === 400 ||
      errorCode === 401 ||
      errorCode === 402
        ? errorMessage
        : errorCode === 403
        ? translate("global-unauthorized")
        : errorCode === 404
        ? translate("vidromaps-map-not-found")
        : errorCode === 500
        ? "Ha ocurrido un error. Inténtelo más tarde."
        : `Error ${errorCode}`}
    </div>
  );
};
export default connect(mapStateToProps)(Register);
